import React, { useEffect, useState } from 'react';

function WeatherComponent() {


    const [data, setData] = useState([]);
    const [updateTime, setUpdateTime] = useState('');
    const [error, setError] = useState(null);


    useEffect(() => {

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('/api/weather');
            const jsonData = await response.json();
            setData(jsonData.properties.periods);
            setUpdateTime(jsonData.properties.updateTime);
            //console.log('Fetched JSON Data:', jsonData);
        } catch (error) {
            //console.error('Error fetching JSON data:', error);
            setError(error.message);
        }
    };

    return (
        <div className="App">
            <header className="App-header">

            </header>
            <main>
                <section className="api-data">
                    <h2>Weather Forecast</h2>
                    {error ? (
                        <p>Error: {error}</p>
                    ) : (
                        <>
                            <p><strong>Last Updated:</strong> {new Date(updateTime).toLocaleString()}</p>
                            {data.length > 0 ? (
                                <div className="response-list">
                                    {data.map((item, index) => (
                                        <div key={index} className="response">
                                            <h3>{item.name}</h3>
                                            <p> <img src={item.icon} alt={item.shortForecast} />{item.shortForecast}</p>

                                            {/* <p className="time"><strong>Start Time:</strong> {new Date(item.startTime).toLocaleString()}</p>
                                            <p className="time"><strong>End Time:</strong> {new Date(item.endTime).toLocaleString()}</p> */}
                                            <p><strong>Temperature:</strong> {item.temperature} {item.temperatureUnit}</p>
                                            <p><strong>Wind:</strong> {item.windSpeed} {item.windDirection}</p>
                                            <p><strong>Short Forecast:</strong> {item.shortForecast}</p>
                                            <p><strong>Detailed Forecast:</strong> {item.detailedForecast}</p>

                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </>
                    )}
                </section>

            </main>
        </div>
    );
}

export default WeatherComponent;