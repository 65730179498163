import React, { useState } from 'react';
import FormComponent from './FormComponent';

function HomePage() {
    const [serverResponse, setServerResponse] = useState(null);

    const handleResponse = (response) => {
        setServerResponse(response);
    };

    return (
        <div>
            <h1>Home Page</h1>
            <p>Welcome to the home page!</p>
            {/* <FormComponent onResponse={handleResponse} />
            {serverResponse && (
                <div>
                    <h2>Server Response</h2>
                    <pre>{JSON.stringify(serverResponse, null, 2)}</pre>
                </div>
            )} */}
        </div>
    );
}

export default HomePage;