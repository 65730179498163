import React, { useState, useEffect } from 'react';

function ChatGPTComponent() {
    const [userInput, setUserInput] = useState('');
    const [data, setData] = useState(null);
    const [inputError, setInputError] = useState('');
    const [throttled, setThrottled] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        // Fetch CSRF token from the server
        fetch('/api/csrf-token')
            .then(response => response.json())
            .then(data => setCsrfToken(data.csrfToken));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!userInput) {
            setInputError('Input cannot be empty');
            return;
        }

        // Clear input error
        setInputError('');
        // Fetch data from the backend with user input
        fetch('/api/chatgpt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken // Include CSRF token in headers
            },
            body: JSON.stringify({ userInput }),
        })
            .then(response => {
                if (response.status === 429) {
                    // Too Many Requests - handle separately
                    setThrottled(true);

                    // Calculate remaining time
                    const throttleTimestamp = localStorage.getItem('throttleTimestamp');
                    if (throttleTimestamp) {
                        const timeElapsed = Date.now() - throttleTimestamp;
                        const timeRemaining = Math.max(0, 15 * 60 * 1000 - timeElapsed);
                        setTimeLeft(timeRemaining);
                        console.log('Time remaining:', timeRemaining);
                    }

                    return null;
                }
                return response.json();
            })
            .then(result => {
                if (result) {
                    setData(result);
                    // Store responses in local storage
                    localStorage.setItem('apiResponses', JSON.stringify(result));
                    localStorage.setItem('throttleTimestamp', Date.now());
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        if (throttled && timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft(prevTimeLeft => {
                    if (prevTimeLeft <= 1000) {
                        clearInterval(timer);
                        setThrottled(false);
                        return 0;
                    }
                    return prevTimeLeft - 1000;
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [throttled, timeLeft]);

    return (
        <div>
            <h1>ChatGPT Component</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Enter your input"
                />
                <button type="submit" disabled={throttled}>Submit</button>
            </form>
            {inputError && <p style={{ color: 'red' }}>{inputError}</p>}
            {throttled && <p style={{ color: 'red' }}>You are being throttled. Please wait {Math.ceil(timeLeft / 1000)} seconds.</p>}
            {data && (
                <div>
                    <h2>Response</h2>
                    <pre>{data.text}</pre>
                    {data.code && data.code.map((codeBlock, index) => (
                        <pre key={index}><code>{codeBlock}</code></pre>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ChatGPTComponent;