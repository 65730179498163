import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import ChatGPTComponent from './ChatGPTComponent';
import WeatherComponent from './WeatherComponent';
import HomePage from './HomePage';

function App() {
  return (
    <Router>
      <div className="App">

        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* <Route path="/about" element={<AboutPage />} /> */}
            <Route path="/weather" element={<WeatherComponent />} />
            <Route path="/chatgpt" element={<ChatGPTComponent />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}
export default App;